<table class="mat-table">
  <thead>
    <tr class="mat-header-row">
      <th class="mat-header-cell">Pick</th>
      <th class="mat-header-cell">Owner</th>
      <th class="mat-header-cell">Rec</th>
      <th class="mat-header-cell">SOS</th>
      <th class="mat-header-cell">Conf</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let pick of draftOrderedTeams; index as i" class="mat-row">
      <td class="mat-cell">
        <span class="rank">{{pick.draftPick}}</span>
      </td>
      <td class="mat-cell">
        <ng-container *ngIf="pick.pickTraded">
          <del>
            <img
              width="25"
              src="https://static.playoffpredictors.com/nfl/{{
                pick.originalPickOwner.teamLogo ||
                  pick.originalPickOwner.teamAbbreviation
              }}.svg"
              class="interactable"
              [title]="pick.originalPickOwner.teamName"
              (click)="leagueService.selectedTeam$.next(pick.originalPickOwner)"
            />
            <span class="teamName interactable" (click)="leagueService.selectedTeam$.next(pick.originalPickOwner)">
              {{ pick.originalPickOwner.teamName }}
            </span>
          </del>
          <mat-icon>chevron_right</mat-icon>
          <img
            width="25"
            src="https://static.playoffpredictors.com/nfl/{{
              pick.tradedTo.teamLogo || pick.tradedTo.teamAbbreviation
            }}.svg"
            class="interactable"
            [title]="pick.tradedTo.teamName"
            (click)="leagueService.selectedTeam$.next(pick.tradedTo)"
          />
          <span class="teamName interactable" (click)="leagueService.selectedTeam$.next(pick.tradedTo)">
            {{ pick.tradedTo.teamName }}
          </span>
        </ng-container>
        <ng-container *ngIf="!pick.pickTraded">
          <img
            class="interactable"
            width="25"
            src="https://static.playoffpredictors.com/nfl/{{
              pick.originalPickOwner.teamLogo ||
                pick.originalPickOwner.teamAbbreviation
            }}.svg"
            [title]="pick.originalPickOwner.teamName"
            (click)="leagueService.selectedTeam$.next(pick.originalPickOwner)"
          />
          <span class="teamName interactable" (click)="leagueService.selectedTeam$.next(pick.originalPickOwner)">
            {{ pick.originalPickOwner.teamName }}
          </span>
        </ng-container>
      </td>
      <td class="mat-cell">
        {{ pick.originalPickOwner.gameRecords.allRecord.toString() }} ({{ pick.originalPickOwner.gameRecords.allRecord.pctNaN | number: '1.3-3' }})
      </td>
      <td class="mat-cell">
        {{ pick.originalPickOwner.sosRecText }} ({{ pick.originalPickOwner.SOS || 0 | number: '1.3-3' }})
      </td>
      <td class="mat-cell">
        {{ pick.originalPickOwner.gameRecords.conferenceRecord.toString() }} ({{ pick.originalPickOwner.gameRecords.conferenceRecord.pctNaN || 0 | number: '1.3-3' }})
      </td>
    </tr>
  </tbody>
</table>
