import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { League } from '@models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LeagueService } from '../../services/league/league.service';

@Component({
  selector: 'app-league-styles',
  templateUrl: './league-styles.component.html',
  styleUrls: ['./league-styles.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LeagueStylesComponent implements OnInit, OnDestroy {
  public css:string;
  destroy$ = new Subject<boolean>();
  constructor(public leagueService: LeagueService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.leagueService.league$.pipe(takeUntil(this.destroy$), map(l => l)).subscribe(l => {
      if(!l){return;}
      var sb = [];
      for(let team of l.teams){
        sb.push(`.pg-${team.teamAbbreviation}{background-color:${team.primaryColor};color:${team.secondaryColor}}`);
        sb.push(`.${team.teamAbbreviation} .${team.teamAbbreviation} {background-color: ${team.primaryColor};color: ${team.secondaryColor};border-color: ${team.secondaryColor};}`)
        sb.push(`.pg-${team.teamAbbreviation}::after { background: url(https://static.playoffpredictors.com/nfl/${team.teamLogo || team.teamAbbreviation}.svg) center center/100% no-repeat;}`)
        sb.push(`button.${team.teamAbbreviation}{min-width:32;min-height:44px}`)
        sb.push(`button.${team.teamAbbreviation}:focus{  background-color: ${team.primaryColor};color: ${team.secondaryColor};border-color: ${team.secondaryColor};}`)
        sb.push(`button.${team.teamAbbreviation}:hover{  background-color: ${team.primaryColor};color: ${team.secondaryColor};border-color: ${team.secondaryColor};}`)
        sb.push(`#team-breakout tr.NONE button.${team.teamAbbreviation}, .gameChannel tr.NONE button.${team.teamAbbreviation}{background-color: inherit; color: inherit; border-color:inherit;}`)
      }
      this.css = sb.join('');
      let existingStyles = document.getElementById("league-styles");
      if(existingStyles){
        existingStyles.remove();
      }
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = "league-styles";
      style.appendChild(document.createTextNode(this.css));
      head.appendChild(style);
    })
  }

  ngOnDestroy(){
    this.destroy$.next(true);
  }

}
