<mat-tab-group [selectedIndex]="selectedIndex">
  <mat-tab>
    <ng-template mat-tab-label> <span (click)="setConference(league.conf1Name)"><img src="https://static.playoffpredictors.com/nfl/AFC.svg" width="32" height="32"/> {{ league.conf1Name }} </span></ng-template>
    <app-conference-scores [conference]="league.conf1Name"> </app-conference-scores>
  </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label><span (click)="setConference(league.conf2Name)"> <img src="https://static.playoffpredictors.com/nfl/NFC.svg" width="32" height="32"/> {{ league.conf2Name }} </span></ng-template>
      <app-conference-scores [conference]="league.conf2Name"></app-conference-scores>
    </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label> <span (click)="setConference('All')"> <img src="https://static.playoffpredictors.com/nfl/AFC.svg" width="32" height="32"/> Combined <img src="https://static.playoffpredictors.com/nfl/NFC.svg" width="32" height="32"/> </span></ng-template>
      <app-conference-scores [conference]="'All'"></app-conference-scores>
  </mat-tab>
</mat-tab-group>
