import { Helpers } from "@helpers/helpers";
import {
  League,
  UpdateReason,
} from "@models";
import { WeekService } from "./../../services/week/week.service";
import { LeagueService } from "./../../services/league/league.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { SelectionType } from "@models";
import { UrlSerializerService } from "@services/url-serializer/url-serializer.service";
import { PostSeasonService } from "../../services/post-season/post-season.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { LeagueSettingsService } from "../../services/league-settings/league-settings.service";
import { AuthenticationService } from "@services/authentication/authentication.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LoginDialogComponent } from "app/account/templates/login-dialog/login-dialog.component";

@Component({
  selector: "app-schedule-control-bar",
  templateUrl: "./schedule-control-bar.component.html",
  styleUrls: ["./schedule-control-bar.component.scss"],
})
export class ScheduleControlBarComponent implements OnInit, OnDestroy {
  league: League;
  destroy$ = new Subject<boolean>();
  get displayLockOption() {
    return this.weekService.week <= this.leagueSettings.numRegularSeasonWeeks;
  }
  constructor(
    private leagueService: LeagueService,
    private weekService: WeekService,
    private postSeasonService: PostSeasonService,
    private urlService: UrlSerializerService,
    private leagueSettings: LeagueSettingsService,
    public authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.league = this.leagueService.league$.value;
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => (this.league = league));
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  resetWeek() {
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        game.updateReason = UpdateReason.Cleared;
        game.selectionType = SelectionType.none;
        this.leagueService.pickNone(game);
        game.scores.a = null;
        game.scores.h = null;
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          SelectionType.none,
          false
        );
      }
    }

    this.leagueService.standingsSet$.next(true);
  }
  randomizeWeek() {
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        this.leagueService.pickNone(game);
      }
    }
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        game.scores.a = null;
        game.scores.h = null;

        let selection = 0;
        if (
          this.weekService.week <= this.leagueSettings.numRegularSeasonWeeks
        ) {
          for (let j = 0; j < 4; j++) {
            selection = (Math.floor(Math.random() * 3) + 1) as SelectionType;
            if (selection !== SelectionType.tie) {
              break;
            }
          }
        } else {
          selection = (Math.floor(Math.random() * 2) + 1) as SelectionType;
        }
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          selection,
          false
        );
        switch (selection) {
          case SelectionType.awayWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.away);
            break;
          case SelectionType.homeWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.home);
            break;
          case SelectionType.tie:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTie(game);
            break;
        }
      }
    }
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const firstPostSeasonWeek = this.leagueSettings.numRegularSeasonWeeks + 1;
    switch (this.weekService.week) {
      case firstPostSeasonWeek: {
        this.postSeasonService.seedDivisionGames(
          conf1Top,
          conf2Top,
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 1: {
        this.postSeasonService.seedConferenceChampionshipGames(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 2: {
        this.postSeasonService.seedSuperbowl(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      default: {
        break;
      }
    }

    this.leagueService.standingsSet$.next(true);
  }
  randomizeWeekUnpickedGames() {
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        if(game.selectionMade !== SelectionType.none){
          continue;
        }
        game.scores.a = null;
        game.scores.h = null;

        let selection = 0;
        if (
          this.weekService.week <= this.leagueSettings.numRegularSeasonWeeks
        ) {
          for (let j = 0; j < 4; j++) {
            selection = (Math.floor(Math.random() * 3) + 1) as SelectionType;
            if (selection !== SelectionType.tie) {
              break;
            }
          }
        } else {
          selection = (Math.floor(Math.random() * 2) + 1) as SelectionType;
        }
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          selection,
          false
        );
        switch (selection) {
          case SelectionType.awayWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.away);
            break;
          case SelectionType.homeWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.home);
            break;
          case SelectionType.tie:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTie(game);
            break;
        }
      }
    }
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const firstPostSeasonWeek = this.leagueSettings.numRegularSeasonWeeks + 1;
    switch (this.weekService.week) {
      case firstPostSeasonWeek: {
        this.postSeasonService.seedDivisionGames(
          conf1Top,
          conf2Top,
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 1: {
        this.postSeasonService.seedConferenceChampionshipGames(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 2: {
        this.postSeasonService.seedSuperbowl(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      default: {
        break;
      }
    }

    this.leagueService.standingsSet$.next(true);
  }
  bestRecordWins() {
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        if(game.selectionMade !== SelectionType.none){
          continue;
        }
        game.scores.a = null;
        game.scores.h = null;

        let selection = 0;
        if(game.home.gameRecords.allRecord.pct >= game.away.gameRecords.allRecord.pct){
          selection = SelectionType.homeWin;
        }else{
          selection = SelectionType.awayWin;
        }
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          selection,
          false
        );
        switch (selection) {
          case SelectionType.awayWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.away);
            break;
          case SelectionType.homeWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.home);
            break;
          case SelectionType.tie:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTie(game);
            break;
        }
      }
    }
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const firstPostSeasonWeek = this.leagueSettings.numRegularSeasonWeeks + 1;
    switch (this.weekService.week) {
      case firstPostSeasonWeek: {
        this.postSeasonService.seedDivisionGames(
          conf1Top,
          conf2Top,
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 1: {
        this.postSeasonService.seedConferenceChampionshipGames(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 2: {
        this.postSeasonService.seedSuperbowl(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      default: {
        break;
      }
    }

    this.leagueService.standingsSet$.next(true);
  }
  randomizeAllUnpickedNfcWins() {
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        if(game.selectionMade !== SelectionType.none || game.home.conference == game.away.conference){
          continue;
        }
        game.scores.a = null;
        game.scores.h = null;

        let selection = 0;
        if(game.home.conference == "NFC"){
          selection = SelectionType.homeWin;
        }else{
          selection = SelectionType.awayWin;
        }
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          selection,
          false
        );
        switch (selection) {
          case SelectionType.awayWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.away);
            break;
          case SelectionType.homeWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.home);
            break;
          case SelectionType.tie:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTie(game);
            break;
        }
      }
    }
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const firstPostSeasonWeek = this.leagueSettings.numRegularSeasonWeeks + 1;
    switch (this.weekService.week) {
      case firstPostSeasonWeek: {
        this.postSeasonService.seedDivisionGames(
          conf1Top,
          conf2Top,
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 1: {
        this.postSeasonService.seedConferenceChampionshipGames(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 2: {
        this.postSeasonService.seedSuperbowl(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      default: {
        break;
      }
    }

    this.leagueService.standingsSet$.next(true);
  }
  randomizeAllUnpickedAfcWins() {
    for (const gameSet of this.weekService.activeGames) {
      for (const game of gameSet) {
        if(game.selectionMade !== SelectionType.none || game.home.conference == game.away.conference){
          continue;
        }
        game.scores.a = null;
        game.scores.h = null;

        let selection = 0;
        if(game.home.conference == "AFC"){
          selection = SelectionType.homeWin;
        }else{
          selection = SelectionType.awayWin;
        }
        this.urlService.setSerializationArrayItem(
          game.gameScheduleNum,
          selection,
          false
        );
        switch (selection) {
          case SelectionType.awayWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.away);
            break;
          case SelectionType.homeWin:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTeam(game, game.home);
            break;
          case SelectionType.tie:
            game.updateReason = UpdateReason.Randomized;
            this.leagueService.pickTie(game);
            break;
        }
      }
    }
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const firstPostSeasonWeek = this.leagueSettings.numRegularSeasonWeeks + 1;
    switch (this.weekService.week) {
      case firstPostSeasonWeek: {
        this.postSeasonService.seedDivisionGames(
          conf1Top,
          conf2Top,
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 1: {
        this.postSeasonService.seedConferenceChampionshipGames(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      case firstPostSeasonWeek + 2: {
        this.postSeasonService.seedSuperbowl(
          this.league,
          this.urlService.gameSerializationArray
        );
        break;
      }
      default: {
        break;
      }
    }

    this.leagueService.standingsSet$.next(true);
  }
  showLoginModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog.open(LoginDialogComponent, dialogConfig);
  }
  saveScenario() {}
}
