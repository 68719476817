import { LeagueSettingsService } from "./../../services/league-settings/league-settings.service";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Team, League } from "@models";
import { FormControl } from "@angular/forms";
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { first, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { LeagueService } from "./../../services/league/league.service";
import { Helpers } from "@helpers/helpers";
import { UserSettingsService } from "@services/index";

@Component({
  selector: "app-conference-scores",
  templateUrl: "./conference-scores.component.html",
  styleUrls: ["./conference-scores.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConferenceScoresComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  numPlayoffTeams: number =
    this.leagueSettingsService.numPlayoffTeamsPerConference;
  @Input()
  conference: string;
  @Input()
  dataSource: MatTableDataSource<Team>;
  selectedColumns = new FormControl();
  selectedTeams = new FormControl();
  allColumns = [
    "index",
    "Conference Rank",
    "Logo",
    "Name",
    "Division",
    "Overall Record",
    "Conference Record",
    "Division Record",
    "Home Record",
    "Away Record",
    "vs Playoff Teams",
    "SOS",
    "SOS Rec",
    "SOS Fractional",
    "SOV",
    "SOV Rec",
    "SOV Fractional",
    "SOL",
    "SOL Rec",
    "SOL Fractional",
    "Strength Differential",
    "vs Good",
    "vs Bad",
    "PF",
    "PA",
    "PD",
    "PPG",
    "PPGA",
    "Pythagorean Expectation",
    "CPF",
    "CPA",
    "CPD",
    "Points For Rank",
    "Points Against Rank",
    "League Combined Ranking",
    "Conference Points For Rank",
    "Conference Points Against Rank",
    "Conference Combined Ranking",
  ];
  columns = [
    "index",
    "Logo",
    "Overall Record",
    "Conference Record",
    "Division Record",
    "Home Record",
    "Away Record",
    "vs Playoff Teams",
    "SOS",
    "SOS Rec",
    "SOV",
    "SOV Rec",
    "vs Good",
    "vs Bad",
    "PF",
    "PA",
    "PD",
    "PPG",
    "PPGA",
    "Pythagorean Expectation",
    "CPF",
    "CPA",
    "CPD",
    "Conference Combined Ranking",
    "League Combined Ranking",
  ];
  teams = [];
  teamsSelected: Team[] = [];
  constructor(
    private leagueService: LeagueService,
    private leagueSettingsService: LeagueSettingsService,
    private userSettingsService: UserSettingsService
  ) {}

  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  ngOnInit() {
    let prevColumns = localStorage.getItem("columns");
    if (prevColumns) {
      this.columns = JSON.parse(prevColumns);
    }
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => {
        if (this.conference !== "All") {
          this.teams = league.teams
            .filter((p) => p.conference == this.conference)
            .sort((a, b) => a.teamName.localeCompare(b.teamName));
          this.dataSource = new MatTableDataSource(this.teams);
        } else {
          this.teams = league.teams.sort((a, b) =>
            a.teamName.localeCompare(b.teamName)
          );
          this.dataSource = new MatTableDataSource(this.teams);
        }
        this.sort.active =
          this.userSettingsService.userConfig.nfl.standingsSortOrderProperty ||
          "Conference Rank";
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case "Overall Record":
              return item.gameRecords.allRecord.pct;
            case "Conference Record":
              return item.gameRecords.conferenceRecord.pct;
            case "Division Record":
              return item.gameRecords.divisionRecord.pct;
            case "Home Record":
              return item.gameRecords.homeRecord.pct;
            case "Away Record":
              return item.gameRecords.awayRecord.pct;
            case "vs Good":
              return item.gameRecords.winningTeamsRecord.pct;
            case "vs Bad":
              return item.gameRecords.losingTeamsRecord.pct;
            case "Conference Rank":
              return item.conferenceRank;
            case "vs Playoff Teams":
              return item.PTRecord;
            case "SOS Rec":
              return item.SOS;
            case "SOV Rec":
              return item.SOV;
            case "SOL Rec":
              return item.SOL;
            case "SOS Fractional":
              return item.SOS;
            case "SOV Fractional":
              return item.SOV;
            case "SOL Fractional":
              return item.SOL;
            case "Strength Differential":
              return item.strengthDifferential;
            case "PF":
              return item.pf;
            case "PA":
              return item.pa;
            case "PD":
              return item.pd;
            case "PPG":
              return item.ppg;
            case "PPGA":
              return item.ppga;
            case "Pythagorean Expectation":
              return item.nflPythagoreanExpectation;
            case "CPF":
              return item.cpf;
            case "CPA":
              return item.cpa;
            case "CPD":
              return item.cpd;
            case "Points For Rank":
              return item.pfRank;
            case "Conference Points For Rank":
              return item.cpfRank;
            case "Points Against Rank":
              return item.paRank;
            case "Conference Points Against Rank":
              return item.cpaRank;
            case "Conference Combined Ranking":
              return item.conferenceCombinedRanking;
            case "League Combined Ranking":
              return item.leagueCombinedRanking;
            default:
              return item[property];
          }
        };
        this.dataSource.filterPredicate = (team, filter) => {
          let recast = (<any>filter) as number[];
          if (filter.length) {
            return recast.filter((id) => id == team.teamId).length > 0;
          }
          return true;
        };
        this.dataSource.sort = this.sort;
        this.selectedColumns.setValue(this.columns);
        this.selectedColumns.valueChanges
          .pipe(takeUntil(this.destroy$))
          .subscribe((values) => {
            this.columns = values;
          });
        this.selectedTeams.valueChanges
          .pipe(takeUntil(this.destroy$))
          .subscribe((values) => {
            this.teamsSelected = values;
            this.dataSource.filter = values;
          });
      });

    this.leagueService.standingsSet$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.sort.sort(<MatSortable>{
          id:
            this.userSettingsService.userConfig.nfl
              .standingsSortOrderProperty || "Conference Rank",
          start:
            this.userSettingsService.userConfig.nfl
              .standingsSortOrderDirection || "asc",
        });
      });

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "Overall Record":
          return item.gameRecords.allRecord.pct;
        case "Conference Record":
          return item.gameRecords.conferenceRecord.pct;
        case "Division Record":
          return item.gameRecords.divisionRecord.pct;
        case "Home Record":
          return item.gameRecords.homeRecord.pct;
        case "Away Record":
          return item.gameRecords.awayRecord.pct;
        case "vs Good":
          return item.gameRecords.winningTeamsRecord.pct;
        case "vs Bad":
          return item.gameRecords.losingTeamsRecord.pct;
        case "Conference Rank":
          return item.conferenceRank;
        case "vs Playoff Teams":
          return item.PTRecord;
        case "SOS Rec":
          return item.SOS;
        case "SOV Rec":
          return item.SOV;
        case "SOS Fractional":
          return item.SOS;
        case "SOV Fractional":
          return item.SOV;
        case "SOL Fractional":
          return item.SOL;
        case "Strength Differential":
          return item.strengthDifferential;
        case "PF":
          return item.pf;
        case "PA":
          return item.pa;
        case "PD":
          return item.pd;
        case "PPG":
          return item.ppg;
        case "PPGA":
          return item.ppga;
        case "Pythagorean Expectation":
          return item.nflPythagoreanExpectation;
        case "CPF":
          return item.cpf;
        case "CPA":
          return item.cpa;
        case "CPD":
          return item.cpd;
        case "Points For Rank":
          return item.pfRank;
        case "Conference Points For Rank":
          return item.cpfRank;
        case "Points Against Rank":
          return item.paRank;
        case "Conference Points Against Rank":
          return item.cpaRank;
        case "Conference Combined Ranking":
          return item.conferenceCombinedRanking;
        case "League Combined Ranking":
          return item.leagueCombinedRanking;
        default:
          return item[property];
      }
    };
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      let hasChanges = false;
      if (
        this.userSettingsService.userConfig.nfl.standingsSortOrderDirection !=
        v.direction
      ) {
        this.userSettingsService.userConfig.nfl.standingsSortOrderDirection =
          v.direction;
        hasChanges = true;
      }
      if (
        this.userSettingsService.userConfig.nfl.standingsSortOrderProperty !=
        v.active
      ) {
        this.userSettingsService.userConfig.nfl.standingsSortOrderProperty =
          v.active;
        hasChanges = true;
      }
      if (hasChanges) {
        this.userSettingsService.saveStorage();
      }
    });
    this.dataSource.sort = this.sort;

    this.selectedColumns.setValue(this.columns);
    this.selectedColumns.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        localStorage.setItem("columns", JSON.stringify(values));

        this.columns = values;
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }

  teamSelected(team) {
    this.leagueService.selectedTeam$.next(team);
  }
}
