import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[app-scroll-indicator]',
})
export class ScrollIndicatorDirective implements AfterViewInit {
  private scrollableElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Locate the scrollable element inside the projected content
    this.scrollableElement = this.el.nativeElement.querySelector('.scroll-container');

    if (this.scrollableElement) {
      // Attach the scroll event listener
      this.renderer.listen(this.scrollableElement, 'scroll', () => {
        this.updateScrollIndicators();
      });

      // Initialize scroll indicators
      this.updateScrollIndicators();
    } else {
      console.error('Scrollable element not found inside the directive container.');
    }
  }

  private updateScrollIndicators() {
    const element = this.scrollableElement;
    if (!element) return;

    const { scrollLeft, scrollWidth, clientWidth } = element;

    // Add/remove the left shadow class
    if (scrollLeft > 0) {
      this.renderer.addClass(this.el.nativeElement, 'scroll-left');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'scroll-left');
    }

    // Add/remove the right shadow class
    if (scrollLeft + clientWidth < scrollWidth) {
      this.renderer.addClass(this.el.nativeElement, 'scroll-right');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'scroll-right');
    }
  }
}
