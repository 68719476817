<h3>Saved Scenarios</h3>
<ng-container *ngIf="isLoggedIn">
  <table
    #table
    mat-table
    [dataSource]="watchlists"
    cdkDropList
    [cdkDropListData]="watchlists"
    [cdkDropListDisabled]="dragDisabled"
    (cdkDropListDropped)="onListDrop($event)"
  >
    <ng-container matColumnDef="sortOrder">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon cdkDragHandle style="pointer-events: all; cursor: move"  (mousedown)="dragDisabled = false;" (touchstart)="dragDisabled = false;"
          >drag_indicator</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="watchlistName">
      <th mat-header-cell *matHeaderCellDef>Scenario Name</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="!element.isEdit">
          {{ element.watchlistName }}
        </div>
        <div *ngIf="element.isEdit">
          <input
            type="text"
            [attr.disabled]="element.isSaving ? '' : null"
            [(ngModel)]="element.watchlistName"
          />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="!element.isEdit">
          {{ element.description }}
        </div>
        <div *ngIf="element.isEdit">
          <textarea
            [attr.disabled]="element.isSaving ? '' : null"
            [(ngModel)]="element.description"
          ></textarea>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef>Created On</th>
      <td mat-cell *matCellDef="let element">{{ element.createdOn | date }}</td>
    </ng-container>
    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.isEdit" class="button-row">
          <button
            mat-button
            mat-raised-button
            label="save"
            aria-label="Save"
            (click)="saveRow(element)"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button
            mat-button
            mat-raised-button
            color="warn"
            label="cancel"
            aria-label="cancel"
            (click)="cancelEdit(element)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <div *ngIf="!element.isEdit" class="button-row">
          <button
            *ngIf="element.version == 2"
            mat-button
            mat-raised-button
            [title]="element.editScenarioLabel"
            [attr.label]="element.editScenarioLabel"
            [attr.aria-label]="element.editScenarioLabel"
            (click)="editRow(element)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-button
            mat-raised-button
            [title]="element.viewScenarioLabel"
            [attr.label]="element.viewScenarioLabel"
            [attr.aria-label]="element.viewScenarioLabel"
            (click)="viewScenario(element)"
          >
            <mat-icon>preview</mat-icon>
          </button>
          <button
            mat-button
            mat-raised-button
            [title]="element.deleteScenarioLabel"
            [attr.label]="element.deleteScenarioLabel"
            [attr.aria-label]="element.deleteScenarioLabel"
            (click)="deleteScenario(element)"
          >
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="[
        'sortOrder',
        'watchlistName',
        'description',
        'createdOn',
        'operations'
      ]"
    ></tr>
    <tr
      cdkDrag
      (cdkDragStarted)="dragStarted($event)"
      [cdkDragData]="row"
      mat-row
      *matRowDef="
        let row;
        columns: [
          'sortOrder',
          'watchlistName',
          'description',
          'createdOn',
          'operations'
        ]
      "
    ></tr>
  </table>
</ng-container>
<ng-container *ngIf="!isLoggedIn">
  <button mat-button mat-raised-button (click)="showLoginModal()">
    Log in to view/save scenarios
  </button>
</ng-container>
