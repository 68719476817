import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlaySheetPost } from '@models';
export enum CategoryMap{
  All = 0,
  Announcements = 55,
  Nfl2023Season = 4,
  NflAll = 2
}
@Injectable({
  providedIn: 'root'
})
export class PlaysheetService {
  constructor(private http: HttpClient) { }

  getPostsInCategory(category:CategoryMap, count?: number, exceptCategories?:CategoryMap[],){
    let url = 'https://playsheet.playoffpredictors.com/wp-json/wp/v2/posts';
    let params = []
    if(category != CategoryMap.All){
      params.push(`categories=${category}`);
    }
    if(count){
      params.push(`per_page=${count}`)
    }
    if(exceptCategories?.length > 0){
      params.push(`&categories_exclude=${exceptCategories.join(',')}`)
    }
    if(params.length > 0){
      return this.http.get<PlaySheetPost[]>(`${url}?${params.join('&')}`)
    }
    else{
      return this.http.get<PlaySheetPost[]>(`${url}`);
    }
  }
}
