import memoize from 'fast-memoize';
import { CommonGamesMethodology } from '../models/export/CommonGamesMethodology';
import { Team } from '../models/export/team.model';
import { Game } from '../models/export/game.model';
import * as Enumerable from 'linq';
import { LZString } from '@helpers/lz-string';
import { LeagueSettingsService } from 'app/football/nfl/services/league-settings/league-settings.service';
import { GameRecord, SelectionType, UserConfig } from '@models';
import { UserSettingsService } from '@services/index';
export class Helpers {
  static worstCommonPct(teams: Array<Team>, leagueSettings: LeagueSettingsService, userSettings: UserConfig, isWildcard?: boolean) {
    const commonRecord = {},
      foes = [],
      opponents = {};

    for (const team of teams) {
      opponents[team.teamAbbreviation] = {};
      opponents[team.teamAbbreviation]["teamsPlayed"] = Enumerable.from(
        Helpers.getOpponents(team, leagueSettings)
      )
        .distinct()
        .toArray();
      commonRecord[team.teamAbbreviation] = [0, 0, 0, 0];
    }
    const commonOpponents = {};
    // tslint:disable-next-line:forin
    for (const i in opponents) {
      // tslint:disable-next-line:forin
      for (const team in opponents[i].teamsPlayed) {
        commonOpponents[opponents[i].teamsPlayed[team]] = 0;
      }
    }
    // tslint:disable-next-line:forin
    for (const i in opponents) {
      // tslint:disable-next-line:forin
      for (const team in opponents[i].teamsPlayed) {
        commonOpponents[opponents[i].teamsPlayed[team]]++;
      }
    }
    let maxCommon = 0;
    // crawl the object and find the highest integer value
    for (const opponent in commonOpponents) {
      if (commonOpponents[opponent] > maxCommon) {
        maxCommon = commonOpponents[opponent];
      }
    }
    if (maxCommon === 1) {
      return teams;
    }
    // push the teams that have been played the same # of times to the array
    for (const opponent in commonOpponents) {
      if (commonOpponents[opponent] === maxCommon && maxCommon !== 1) {
        foes.push(opponent);
      }
    }

    // Verify that the CG comparisons are valid, in that every team needs to have played every other team
    for (const foeName of foes) {
      for (const team of teams) {
        const exists = team.schedule.find((game: Game) => {
          if (game && foeName !== team.teamAbbreviation) {
            return (
              game.home.teamAbbreviation === foeName ||
              game.away.teamAbbreviation === foeName
            );
          }
        });
        if (!exists) {
          return teams;
        }
      }
    }

    for (const team of teams) {
      const teamName = team.teamAbbreviation,
        teamRec = commonRecord[teamName];
      for (const foeName of foes) {
        for (const game of team.schedule) {
          if (
            game &&
            game.selectionType !== undefined &&
            game.selectionMade &&
            Helpers.bothTeamNamesInGame(game, teamName, foeName)
          ) {
            if(game.selectionType === SelectionType.noContest){
              continue;
            }
            if (game.selectionType !== SelectionType.tie) {
              if (game.teamThatWon.teamAbbreviation === teamName) {
                teamRec[0]++;
              } else if (game.teamThatWon.teamAbbreviation !== teamName) {
                teamRec[1]++;
              }
            } else {
              teamRec[2]++;
            }
          } else if (
            game &&
            !game.selectionMade &&
            Helpers.bothTeamNamesInGame(game, teamName, foeName)
          ) {
            teamRec[3]++;
          }
        }
      }
    }

    if (
      userSettings.nfl.commonGamesMethodology !==
      CommonGamesMethodology.allScheduledGames
    ) {
      for (const teamName in commonRecord) {
        if (commonRecord.hasOwnProperty(teamName)) {
          const rec = commonRecord[teamName];
          if (rec[0] === 0 && rec[1] === 0 && rec[2] === 0) {
            return teams;
          }
        }
      }
    }

    for (const team of teams) {
      const teamRec = commonRecord[team.teamAbbreviation];
      if (
        userSettings.nfl.commonGamesMethodology ===
        CommonGamesMethodology.allScheduledGames
      ) {
        // must have 4 common games to count
        if (
          isWildcard &&
          teamRec[0] + teamRec[1] + teamRec[2] + teamRec[3] < 4
        ) {
          return teams;
        }
      } else {
        if (isWildcard && teamRec[0] + teamRec[1] + teamRec[2] < 4) {
          return teams;
        }
      }

      team.worstCommonCalcPct = Helpers.calcPct(
        teamRec[0],
        teamRec[1],
        teamRec[2]
      );
    }

    const groups = Enumerable.from(teams)
      .orderBy((p) => p.worstCommonCalcPct)
      .groupBy((p) => p.worstCommonCalcPct)
      .toArray();
    const remaining = groups[0].getSource();
    return remaining;
  }

  static bestCommonPct(teams: Array<Team>, leagueSettings: LeagueSettingsService, userSettings: UserConfig, isWildcard?: boolean) {
    const commonRecord = {},
      foes = [],
      opponents = {},
      self = this;

    for (const team of teams) {
      opponents[team.teamAbbreviation] = {};
      opponents[team.teamAbbreviation]["teamsPlayed"] = Enumerable.from(
        Helpers.getOpponents(team, leagueSettings)
      )
        .distinct()
        .toArray();
      commonRecord[team.teamAbbreviation] = [0, 0, 0, 0];
    }
    const commonOpponents = {};
    // tslint:disable-next-line:forin
    for (const i in opponents) {
      // tslint:disable-next-line:forin
      for (const team in opponents[i].teamsPlayed) {
        commonOpponents[opponents[i].teamsPlayed[team]] = 0;
      }
    }
    // tslint:disable-next-line:forin
    for (const i in opponents) {
      // tslint:disable-next-line:forin
      for (const team in opponents[i].teamsPlayed) {
        commonOpponents[opponents[i].teamsPlayed[team]]++;
      }
    }
    let maxCommon = 0;
    // crawl the object and find the highest integer value
    for (const opponent in commonOpponents) {
      if (commonOpponents[opponent] > maxCommon) {
        maxCommon = commonOpponents[opponent];
      }
    }
    if (maxCommon === 1) {
      return teams;
    }
    // push the teams that have been played the same # of times to the array
    for (const opponent in commonOpponents) {
      if (commonOpponents[opponent] === maxCommon && maxCommon !== 1) {
        foes.push(opponent);
      }
    }

    // Verify that the CG comparisons are valid, in that every team needs to have played every other team
    for (const foeName of foes) {
      for (const team of teams) {
        const exists = team.schedule.find((game: Game) => {
          if (game && foeName !== team.teamAbbreviation) {
            return (
              game.home.teamAbbreviation === foeName ||
              game.away.teamAbbreviation === foeName
            );
          }
        });
        if (!exists) {
          return teams;
        }
      }
    }

    for (const team of teams) {
      const teamName = team.teamAbbreviation,
        teamRec = commonRecord[teamName];
      for (const foeName of foes) {
        for (const game of team.schedule) {
          if (
            game &&
            game.selectionType !== undefined &&
            game.selectionMade &&
            Helpers.bothTeamNamesInGame(game, teamName, foeName)
          ) {
            if(game.selectionType === SelectionType.noContest){
              continue;
            }
            if (game.selectionType !== SelectionType.tie) {
              if (game.teamThatWon.teamAbbreviation === teamName) {
                teamRec[0]++;
              } else if (game.teamThatWon.teamAbbreviation !== teamName) {
                teamRec[1]++;
              }
            } else {
              teamRec[2]++;
            }
          } else if (
            game &&
            !game.selectionMade &&
            Helpers.bothTeamNamesInGame(game, teamName, foeName)
          ) {
            teamRec[3]++;
          }
        }
      }
    }

    if (
      userSettings.nfl.commonGamesMethodology !==
      CommonGamesMethodology.allScheduledGames
    ) {
      for (const teamName in commonRecord) {
        if (commonRecord.hasOwnProperty(teamName)) {
          const rec = commonRecord[teamName];
          if (rec[0] === 0 && rec[1] === 0 && rec[2] === 0) {
            return teams;
          }
        }
      }
    }

    for (const team of teams) {
      const teamRec = commonRecord[team.teamAbbreviation];
      if (
        userSettings.nfl.commonGamesMethodology ===
        CommonGamesMethodology.allScheduledGames
      ) {
        // must have 4 common games to count
        if (
          isWildcard &&
          teamRec[0] + teamRec[1] + teamRec[2] + teamRec[3] < 4
        ) {
          return teams;
        }
      } else {
        if (isWildcard && teamRec[0] + teamRec[1] + teamRec[2] < 4) {
          return teams;
        }
      }

      team.bestCommonCalcPct = Helpers.calcPct(
        teamRec[0],
        teamRec[1],
        teamRec[2]
      );
    }

    const groups = Enumerable.from(teams)
      .orderByDescending((p) => p.bestCommonCalcPct)
      .groupBy((p) => p.bestCommonCalcPct)
      .toArray();
    const remaining = groups[0].getSource();

    if (groups.length > 1) {
      for (const teamRem of remaining) {
        teamRem.tiebreakers["CommonGames"] = commonRecord;
        const foesNames = foes.map((team) => {
          return " " + team;
        });
        teamRem.tiebreakers["Explanations"].push(
          `Common opponents: ${foesNames}`
        );

        teamRem.tiebreakers["Explanations"] = Enumerable.from(teamRem.tiebreakers["Explanations"]).distinct().toArray()
      }
    }

    return remaining;
  }
  static worstHtHConference(teams: Team[], leagueSettings: LeagueSettingsService):Team[] {
      var games = {};
      for (var _team of teams) {
          if (!_team) break;
          for (let _foe of teams.slice(0)) {
              if (!_foe) break;
              if (_team == _foe) {
                  continue;
              }
              let schedule = Helpers.getGamesVsEachOther(_team, _foe, leagueSettings);
              for(let game of schedule){
                  if (game.selectionMade){
                      if (game.selectionType != SelectionType.tie) {
                          games[game.away.teamAbbreviation + "-" + game.home.teamAbbreviation] = game.selectionType;
                      }
                  }
              };
          }
      }

      let foe:Team, i, j, k, l, len, len1, len2, len3, remaining_teams, sweep_unbroken, swept_unbroken, team:Team;

      for (i = 0, len = teams.length; i < len; i++) {
          team = teams[i];
          if (!team) break;
          sweep_unbroken = true;
          for (j = 0, len1 = teams.length; j < len1; j++) {
              foe = teams[j];
              if (!foe) break;
              if (!(foe !== team)) {
                  continue;
              }
              //console.log("hth foe: " + foe().teamAbbreviation);
              //foe Wins && foe Wins
              if (games[team.teamAbbreviation + "-" + foe.teamAbbreviation] !== SelectionType.awayWin
                    && games[foe.teamAbbreviation + "-" + team.teamAbbreviation] !== SelectionType.homeWin) {
                  sweep_unbroken = false;
                  break;
              }
          }
          if (sweep_unbroken) {
              //console.log(team().teamAbbreviation + " sweeps");
              var teamsSwept = [];
              for(let item of Enumerable.from(teams).where((p: Team) => p.teamAbbreviation != team.teamAbbreviation).toArray()) {
                  teamsSwept.push(item);
              }
              return teamsSwept;
          }
      }
      for (k = 0, len2 = teams.length; k < len2; k++) {
          team = teams[k];
          swept_unbroken = true;
          for (l = 0, len3 = teams.length; l < len3; l++) {
              foe = teams[l];
              if (!team || !foe) break;
              if (foe !== team) {
                  if (games[team.teamAbbreviation + "-" + foe.teamAbbreviation] !== SelectionType.homeWin && games[foe.teamAbbreviation + "-" + team.teamAbbreviation] !== SelectionType.awayWin) {
                      swept_unbroken = false;
                      break;
                  }
              }
          }
          if (swept_unbroken) {
              //console.log(team().teamAbbreviation + " swept");
              remaining_teams = teams.slice(0);
              remaining_teams.splice(remaining_teams.indexOf(team), 1);
              return remaining_teams;
          }
      }
      //   console.log("No sweeps");
      return teams;
  }

  static bestNetPointsConferenceGames(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.cpf - p.cpa)
      .groupBy(p => p.cpf - p.cpa)
      .toArray()[0]
      .getSource();
  }

  static bestNetPointsAllGames(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.pf - p.pa)
      .groupBy(p => p.pf - p.pa)
      .toArray()[0]
      .getSource();
  }

  static bestCombinedPfPaRankLeague(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderBy(p => p.leagueCombinedRanking)
      .groupBy(p => p.leagueCombinedRanking)
      .toArray()[0]
      .getSource();
  }
  static bestCombinedPfPaRankConference(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderBy(p => p.conferenceCombinedRanking)
      .groupBy(p => p.conferenceCombinedRanking)
      .toArray()[0]
      .getSource();
  }


  static IsSameConference(team1: Team, team2: Team) {
    return team1.conference === team2.conference;
  }
  static IsSameDivision(team1: Team, team2: Team) {
    return Helpers.IsSameConference(team1, team2) && team1.division === team2.division;
  }


  static calcWLT = memoize(Helpers._calcWLT);

  private static _calcWLT(wins: number, losses: number, ties: number) {
    const denom = wins + losses + ties;
    if (denom === 0) {
      return 0;
    }

    return (wins + 0.5 * ties) / denom;
  }

  private static _calcPct(wins: number, losses: number, ties: number) {
    const denom = wins + losses + ties;
    let behavior = 1;
    try {
      behavior = parseInt(JSON.parse(localStorage['UserConfig']).math._undefinedDenominatorBehavior, 10);
    } catch (ex) {
      // swallow
    }

    if (denom === 0) {
      switch (behavior) {
        default:
        case 1:
          return -1;
        case 2:
          return 0;
        case 3:
          return 0.00000001;
      }
    }

    return (wins + 0.5 * ties) / denom;
  }

  static calcPct = memoize(Helpers._calcPct);

  static bestPct(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.allRecord.pct)
      .groupBy(p => p.gameRecords.allRecord.pct)
      .toArray()[0]
      .getSource();
  }

  static bestPctNaN(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.allRecord.pctNaN)
      .groupBy(p => p.gameRecords.allRecord.pctNaN)
      .toArray()[0]
      .getSource();
  }

  static worstPctNaN(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderBy((p: any) => p.gameRecords.allRecord.pctNaN)
      .groupBy(p => p.gameRecords.allRecord.pctNaN)
      .toArray()[0]
      .getSource();
  }

  static bestDivPct(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.divisionRecord.pct)
      .groupBy(p => p.gameRecords.divisionRecord.pct)
      .toArray()[0]
      .getSource();
  }
  static bestDivPctNaN(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.divisionRecord.pctNaN)
      .groupBy(p => p.gameRecords.divisionRecord.pctNaN)
      .toArray()[0]
      .getSource();
  }
  static worstDivPctNaN(teams: Array<Team>) {
  return Enumerable.from(teams)
    .orderByDescending(p => p.gameRecords.divisionRecord.pctNaN)
    .groupBy(p => p.gameRecords.divisionRecord.pctNaN)
    .toArray()[0]
    .getSource();
  }

  static bestConfPct(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.conferenceRecord.pct)
      .groupBy(p => p.gameRecords.conferenceRecord.pct)
      .toArray()[0]
      .getSource();
  }
  static bestConfPctNaN(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.conferenceRecord.pctNaN)
      .groupBy(p => p.gameRecords.conferenceRecord.pctNaN)
      .toArray()[0]
      .getSource();
  }
  static worstConfPctNaN(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderBy((p: any) => p.gameRecords.conferenceRecord.pctNaN)
      .groupBy(p => p.gameRecords.conferenceRecord.pctNaN)
      .toArray()[0]
      .getSource();
  }
  static bestSOV(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.SOV)
      .groupBy(p => p.SOV)
      .toArray()[0]
      .getSource();
  }
  static worstSOV(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderBy((p: Team) => p.SOV)
      .groupBy(p => p.SOV)
      .toArray()[0]
      .getSource();
  }
  static bestSOS(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.SOS)
      .groupBy(p => p.SOS)
      .toArray()[0]
      .getSource();
  }
  static worstSOS(teams: Array<Team>) {
    if (teams.length) {
      return Enumerable.from(teams)
        .orderBy((p: any) => p.SOS)
        .groupBy(p => p.SOS)
        .toArray()[0]
        .getSource();
    }
    return [];
  }
  static getPct(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.allRecord.pct)
      .groupBy(p => p.gameRecords.allRecord.pct)
      .toArray();
  }
  static getDivPct(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.divisionRecord.pct)
      .groupBy(p => p.gameRecords.divisionRecord.pct)
      .toArray();
  }
  static getConfPct(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.conferenceRecord.pct)
      .groupBy(p => p.gameRecords.conferenceRecord.pct)
      .toArray();
  }
  static getConfPctNaN(teams: Array<Team>) {
    return Enumerable.from(teams)
      .orderByDescending(p => p.gameRecords.conferenceRecord.pctNaN)
      .groupBy(p => p.gameRecords.conferenceRecord.pctNaN)
      .toArray();
  }

  static getOpponents(team: Team, leagueSettings: LeagueSettingsService) {
    const teamName = team.teamAbbreviation;
    const sched = team.schedule.filter(p => p.week <= leagueSettings.numRegularSeasonWeeks);
    let behavior = 1;
    try {
      behavior = parseInt(JSON.parse(localStorage['UserConfig']).nfl._commonGamesMethodology, 10);
    } catch (ex) {
      // swallow
    }
    const cgHandling = behavior;
    return Enumerable.from(sched)
      .where((p: Game) => p != null && (cgHandling === CommonGamesMethodology.requireGamesBePlayed ? p.selectionMade : true) && p.week <= leagueSettings.numRegularSeasonWeeks)
      .select((p: Game) => {
        return p.away.teamAbbreviation === teamName ? p.home.teamAbbreviation : p.away.teamAbbreviation;
      })
      .where(p => p !== teamName)
      .distinct()
      .toArray();
  }

  static bothTeamNamesInGame(game: Game, team1: string, team2: string) {
    return (game.away.teamAbbreviation === team1 || game.away.teamAbbreviation === team2) && (game.home.teamAbbreviation === team1 || game.home.teamAbbreviation === team2);
  }
  static getHTHDivisionGames(left: Team, right: Team, leagueSettings: LeagueSettingsService): Game[] {
    const leftSched = left.schedule;
    const rightSched = right.schedule;
    const allGames = leftSched.concat(rightSched).filter((game: Game) => {
      if (game) {
        return Helpers.bothTeamsInGame(game, left, right) && Helpers.IsSameDivision(game.away, game.home) && game.week <= leagueSettings.numRegularSeasonWeeks;
      }
    });

    return allGames.filter(Helpers.uniqueGames);
  }

  static getGamesVsEachOther(team1: Team, team2: Team, leagueSettings: LeagueSettingsService): Array<Game> {
    const sched = team1.schedule
      .concat(team2.schedule)
      .filter((game: Game) => Helpers.bothTeamsInGame(game, team1, team2))
      .filter(Helpers.uniqueGames)
      .filter((game: Game) => game.week <= leagueSettings.numRegularSeasonWeeks);

    return sched.sort(Helpers.sortByWeek);
  }

  static bothTeamsInGame(game: Game, left: Team, right: Team) {
    return (game.home === left || game.home === right) && (game.away === left || game.away === right);
  }

  static uniqueGames(value, index, self) {
    return self.indexOf(value) === index;
  }
  static sortByGameDate(a: Game, b: Game){
    if(!a.gameDate && !b.gameDate){
      return a.gameScheduleNum - b.gameScheduleNum;
    }
    return a.gameDate.getTime() - b.gameDate.getTime();
  }
  static sortByWeek(a: Game, b: Game) {
    if (+a.week < +b.week) {
      return -1;
    } else if (+a.week > +b.week) {
      return 1;
    } else {
      return 0;
    }
  }
  static conferenceRankSort(a: Team, b: Team) {
    if (+a.conferenceRank < +b.conferenceRank) {
      return -1;
    } else if (+a.conferenceRank > +b.conferenceRank) {
      return 1;
    } else {
      return 0;
    }
  }

  static compress(stringToCompress: string): string {
    return LZString.lz.compressToEncodedURIComponent(stringToCompress);
  }

  static decompress(stringToDecompress: string): string {
    return LZString.lz.decompressFromEncodedURIComponent(stringToDecompress);
  }

  static getOppPct(team: Team, game: Game): number {
    if (game.teamThatWon != null && game.teamThatLost != null) {
      if (team === game.teamThatWon) {
        return game.teamThatLost.gameRecords.allRecord.pct;
      }
      return game.teamThatWon.gameRecords.allRecord.pct;
    }
    return 0;
  }
}
