import { LeagueSettingsService } from "./../../services/league-settings/league-settings.service";
import { TiebreakerService } from "./../../services/tiebreaker/tiebreaker.service";
import { Helpers } from "@helpers/helpers";
import { PostSeasonService } from "./../../services/post-season/post-season.service";
import { WeekService } from "./../../services/week/week.service";
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subject } from "rxjs";
import { LeagueService } from "../../services/league/league.service";
import { SelectionType, League, UpdateReason } from "@models";
import {
  UrlSerializerService,
  AuthenticationService,
  UserSettingsService,
  OnlineService,
} from "@services/index";
import { take, takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LoginDialogComponent } from "app/account/templates/login-dialog/login-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { YesNoDialogComponent } from "app/shared/templates/yes-no-dialog/yes-no-dialog.component";
import { SaveWatchlistModalComponent } from "app/shared/templates/save-watchlist-modal/save-watchlist-modal.component";

@Component({
  selector: "app-control-bar",
  templateUrl: "./control-bar.component.html",
  styleUrls: ["./control-bar.component.css"],
})
export class ControlBarComponent implements OnInit, OnDestroy {
  @Input()
  leagueGuid: string;

  constructor(
    public weekService: WeekService,
    private leagueService: LeagueService,
    private urlService: UrlSerializerService,
    private postSeasonService: PostSeasonService,
    private tiebreakerService: TiebreakerService,
    private leagueSettings: LeagueSettingsService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public userSettings: UserSettingsService,
    private onlineService: OnlineService
  ) {}
  league: League;
  destroy$ = new Subject<boolean>();
  public isOnline = false;
  ngOnInit() {
    this.league = this.leagueService.league$.value;
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((league) => (this.league = league));
    this.onlineService.isOnline$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOnline) => (this.isOnline = isOnline));
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  incrementWeek() {
    this.weekService.incrementWeek();
  }

  decrementWeek() {
    this.weekService.decrementWeek();
  }

  setWeek(week) {
    this.weekService.setWeek(week);
  }

  currentStandings() {
    this.dialog
      .open(YesNoDialogComponent, {
        width: "500px",
        data: {
          title: "Confirm Update",
          message: `Are you sure you want to update standings? This will reset all of your picks.`,
          yesLinkText: "Yes, reset",
          noLinkText: "Cancel",
        },
      })
      .afterClosed()
      .subscribe((yesNo) => {
        if (yesNo) {
          this.weekService.currentStandings();
        }
      });
  }

  reset() {
    this.weekService.reset();
  }

  randomize() {
    const games = this.league.games.slice(
      0,
      this.leagueSettings.numRegularSeasonGames
    );
    for (const game of games) {
      game.scores.a = null;
      game.scores.h = null;
      game.scores.status = null;
      this.leagueService.pickNone(game);
    }
    for (const game of games) {
      let selection = 0;
      let random = Math.floor(Math.random() * 240);
      if(random == 1){
        selection = SelectionType.tie;
      }else if(random % 2 == 0){
        selection = SelectionType.homeWin;
      }else{
        selection = SelectionType.awayWin;
      }
      this.urlService.setSerializationArrayItem(
        game.gameScheduleNum,
        selection,
        false
      );
      switch (selection) {
        case SelectionType.awayWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.home);
          break;
        case SelectionType.tie:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTie(game);
          break;
      }
    }

    this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks);
    this.postSeasonService.reset(this.league);
    this.tiebreakerService.updateStandings(
      this.league.teams,
      this.league.conf1Name,
      this.league.conf2Name
    );
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    this.postSeasonService.seedWildcardGames(
      conf1Top,
      conf2Top,
      this.league,
      this.urlService.gameSerializationArray
    );

    this.leagueService.standingsSet$.next(true);
  }

  randomizeAllUnpicked() {
    const games = this.league.games.slice(
      0,
      this.leagueSettings.numRegularSeasonGames
    );
    for (const game of games) {
      if(game.selectionMade !== SelectionType.none){
        continue;
      }
      let selection = 0;
      let random = Math.floor(Math.random() * 240);
      if(random == 1){
        selection = SelectionType.tie;
      }else if(random % 2 == 0){
        selection = SelectionType.homeWin;
      }else{
        selection = SelectionType.awayWin;
      }
      this.urlService.setSerializationArrayItem(
        game.gameScheduleNum,
        selection,
        false
      );
      switch (selection) {
        case SelectionType.awayWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.home);
          break;
        case SelectionType.tie:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTie(game);
          break;
      }
    }

    this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks);
    this.postSeasonService.reset(this.league);
    this.tiebreakerService.updateStandings(
      this.league.teams,
      this.league.conf1Name,
      this.league.conf2Name
    );
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    this.postSeasonService.seedWildcardGames(
      conf1Top,
      conf2Top,
      this.league,
      this.urlService.gameSerializationArray
    );

    this.leagueService.standingsSet$.next(true);
  }
  randomizeAllBestTeamWins() {
    const games = this.league.games.slice(
      0,
      this.leagueSettings.numRegularSeasonGames
    );
    for (const game of games) {
      if(game.selectionMade !== SelectionType.none){
        continue;
      }
      let selection = 0;
      if(game.home.gameRecords.allRecord.pct >= game.away.gameRecords.allRecord.pct){
        selection = SelectionType.homeWin;
      }else{
        selection = SelectionType.awayWin;
      }
      this.urlService.setSerializationArrayItem(
        game.gameScheduleNum,
        selection,
        false
      );
      switch (selection) {
        case SelectionType.awayWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.home);
          break;
        case SelectionType.tie:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTie(game);
          break;
      }
    }

    this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks);
    this.postSeasonService.reset(this.league);
    this.tiebreakerService.updateStandings(
      this.league.teams,
      this.league.conf1Name,
      this.league.conf2Name
    );
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    this.postSeasonService.seedWildcardGames(
      conf1Top,
      conf2Top,
      this.league,
      this.urlService.gameSerializationArray
    );

    this.leagueService.standingsSet$.next(true);
  }

  randomizeAllUnpickedAfcWins() {
    const games = this.league.games.slice(
      0,
      this.leagueSettings.numRegularSeasonGames
    );
    for (const game of games) {
      if(game.selectionMade !== SelectionType.none || game.away.conference == game.home.conference){
        continue;
      }
      let selection = 0;
      if(game.home.conference == "AFC"){
        selection = SelectionType.homeWin;
      }else{
        selection = SelectionType.awayWin;
      }
      this.urlService.setSerializationArrayItem(
        game.gameScheduleNum,
        selection,
        false
      );
      switch (selection) {
        case SelectionType.awayWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.home);
          break;
        case SelectionType.tie:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTie(game);
          break;
      }
    }

    this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks);
    this.postSeasonService.reset(this.league);
    this.tiebreakerService.updateStandings(
      this.league.teams,
      this.league.conf1Name,
      this.league.conf2Name
    );
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    this.postSeasonService.seedWildcardGames(
      conf1Top,
      conf2Top,
      this.league,
      this.urlService.gameSerializationArray
    );

    this.leagueService.standingsSet$.next(true);
  }
  randomizeAllUnpickedNfcWins() {
    const games = this.league.games.slice(
      0,
      this.leagueSettings.numRegularSeasonGames
    );
    for (const game of games) {
      if(game.selectionMade !== SelectionType.none || game.away.conference == game.home.conference){
        continue;
      }
      let selection = 0;
      if(game.home.conference == "NFC"){
        selection = SelectionType.homeWin;
      }else{
        selection = SelectionType.awayWin;
      }
      this.urlService.setSerializationArrayItem(
        game.gameScheduleNum,
        selection,
        false
      );
      switch (selection) {
        case SelectionType.awayWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.away);
          break;
        case SelectionType.homeWin:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTeam(game, game.home);
          break;
        case SelectionType.tie:
          game.updateReason = UpdateReason.Randomized;
          this.leagueService.pickTie(game);
          break;
      }
    }

    this.weekService.setWeek(this.leagueSettings.numRegularSeasonWeeks);
    this.postSeasonService.reset(this.league);
    this.tiebreakerService.updateStandings(
      this.league.teams,
      this.league.conf1Name,
      this.league.conf2Name
    );
    const conf1Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf1Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    const conf2Top = this.league.teams
      .filter(
        (p) =>
          p.conference === this.league.conf2Name &&
          p.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference
      )
      .sort(Helpers.conferenceRankSort);
    this.postSeasonService.seedWildcardGames(
      conf1Top,
      conf2Top,
      this.league,
      this.urlService.gameSerializationArray
    );

    this.leagueService.standingsSet$.next(true);
  }

  saveScores() {
    const scoreMap = {};

    this.league.games.map((game) => {
      // if they're on the official league, fully re-project all picks into their own scores system
      if (
        this.league.features.isOfficial &&
        this.userSettings.userConfig.nfl.enableScoring
      ) {
        scoreMap[game.gameScheduleNum] = {
          a: game.scores.a,
          h: game.scores.h,
          status: "F",
        };
      } else {
        // if they're in their own system, just update what's been flagged as dirty
        if (
          game.scoresIsDirty &&
          this.userSettings.userConfig.nfl.enableScoring
        ) {
          scoreMap[game.gameScheduleNum] = {
            a: game.scores.a,
            h: game.scores.h,
            status: "F",
          };
        }
      }
      game.scoresIsDirty = false;
    });
    this.leagueService
      .saveScores(this.league.leagueGuid, { scores: scoreMap })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.league.features.isOfficial) {
          this.router.navigate(
            [
              "football/nfl/",
              this.league.leagueGuid,
              this.authenticationService.userValue.id,
            ],
            { queryParams: { L: this.route.snapshot.queryParamMap.get("L") } }
          );
        }
      });
  }
  showLoginModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    const dialogRef = this.dialog
      .open(LoginDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.saveScores();
      });
  }
  saveScenario() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      leagueGuid: this.leagueGuid,
      sportId: this.league.sportId
    };
    const dialogRef = this.dialog
      .open(SaveWatchlistModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {});
  }
}
