<div *ngIf="league.features.allowsScores">
  <h3>Enable Scoring Module</h3>
  <mat-form-field>
    <mat-select
      [(ngModel)]="userSettings.nfl.enableScoring"
      placeholder="Enable Scoring"
    >
      <mat-option [value]="true">yes</mat-option>
      <mat-option [value]="false">no</mat-option>
    </mat-select>
  </mat-form-field>
  <p>
    Enables or Disables the scoring module; this will substantially speed up
    performance. You will need to update the schedule to get current scores.
  </p>
  <p style="color:red"><strong>IMPORTANT:</strong> If you enable scoring, scores will <em>always</em> override your picks.</p>
  <ng-container *ngIf="league.features.liveUpdatesEnabled">
    <h3>Enable Live Updates</h3>
    <mat-form-field>
      <mat-select
        [(ngModel)]="userSettings.nfl.liveUpdatesEnabled"
        [disabled]="!userSettings.nfl.enableScoring"
        placeholder="Enable Live Updates"
      >
        <mat-option [value]="true">yes</mat-option>
        <mat-option [value]="false">no</mat-option>
      </mat-select>
    </mat-form-field>
    <p>
      Enables or Disables live score updates. Disabled if scoring is disabled.
      With this module on, scores will be automatically updated and game
      outcomes update automatically.
    </p>
    <h3>Update Game Outcomes with Live Updates</h3>
    <mat-form-field>
      <mat-select
        [(ngModel)]="userSettings.nfl.updateGameOutcomesWithScoreUpdate"
        [disabled]="!userSettings.nfl.enableScoring"
        placeholder="Update Game Outcomes"
      >
        <mat-option [value]="true">yes</mat-option>
        <mat-option [value]="false">no</mat-option>
      </mat-select>
    </mat-form-field>
    <p>
      If set to yes, the standings will update on all score updates, even if the
      game is not concluded yet, presuming the team in the lead is the winner.
    </p>
    <p>
      Setting to no will only update standings if the game is concluded. Scores
      will still update.
    </p>
  </ng-container>
</div>
<div>
  <h3>Reorder Division Standings Based On Division Leader Seeds</h3>
  <mat-form-field>
    <mat-select
      [(ngModel)]="userSettings.nfl.reorderDivisionStandings"
      placeholder="Reorder division standings"
    >
      <mat-option [value]="true">yes</mat-option>
      <mat-option [value]="false">no</mat-option>
    </mat-select>
  </mat-form-field>
  <p>
    If set to yes, the division standings will re-order themselves based on the
    division leader's seeds. If set to no, the divisions will always appear in
    the same order.
  </p>
</div>
<div></div>
<div>
  <h3>Pct Calculation System</h3>
  <mat-form-field>
    <mat-select
      [(ngModel)]="userSettings.math.undefinedDenominatorBehavior"
      placeholder="Pct Calculation System"
    >
      <mat-option [value]="1">0-0 &lt; 0.000 (default)</mat-option>
      <mat-option [value]="2">0-0 == 0.000</mat-option>
      <mat-option [value]="3">0-0 &gt; 0.000 (ESPN/CBS)</mat-option>
    </mat-select>
  </mat-form-field>
  <p>
    This is <strong>Extremely</strong> important in the early season when not
    many games have been played.
  </p>
  <p>
    Essentially, changing this behavior will change how we weigh undefined
    behavior in our tiebreakers. By default, we treat a 0-0 calculation as less
    than 0.000 -- so a team that is 0-0 will be lower than a team that is 0-1.
  </p>
  <p>
    Since this behavior is literally undefined, sites will tend to implement
    this calculation their own way. Notably, ESPN and CBS treat a 0-0 score as
    if it's greater than 0.000.
  </p>
</div>
<div>
  <h3>Common Games Handling</h3>
  <mat-form-field>
    <mat-select
      [(ngModel)]="userSettings.nfl.commonGamesMethodology"
      placeholder="Common Games Handling"
    >
      <mat-option [value]="1">Games Must Be Played (default)</mat-option>
      <mat-option [value]="2">All Scheduled</mat-option>
    </mat-select>
  </mat-form-field>
  <h4>Definitions</h4>
  <dl>
    <dt>Games Must Be Played (default)</dt>
    <dd>
      The game must have an outcome in order for it to count towards creating a
      common opponent.
    </dd>
    <dt>All Scheduled</dt>
    <dd>
      It will consider all games that are scheduled for the season when deciding
      if teams are common or not. Please note, that for wildcards this means
      that there will <strong>not</strong> need to be 4 games played to count
      (just 4 scheduled)
    </dd>
  </dl>
  <p>
    At the end of the season, both of these options will yield the same result,
    but it may impact ranking during the season. The default is "Games must be
    played", but other sites may use a different methodology.
  </p>
  <p>
    Please keep in mind that this is set on a per-user basis. When linking to
    this league the methodology used will be up to the user (likely the
    default), so they may see different results than you do.
  </p>
</div>
<div>
  <h3>Strength of Schedule Methodology</h3>
  <mat-form-field>
    <mat-select
      [(ngModel)]="userSettings.nfl.sosMethodology"
      placeholder="Strength of Schedule/Victory Methodology"
    >
      <mat-option [value]="1">Games Must Be Played (default)</mat-option>
      <mat-option [value]="2">All Scheduled</mat-option>
    </mat-select>
  </mat-form-field>
  <h4>Definitions</h4>
  <dl>
    <dt>Games Must Be Played (default)</dt>
    <dd>
      The game must have an outcome in order for it to count towards a team's Strength of Schedule.
    </dd>
    <dt>All Scheduled</dt>
    <dd>
     All games on a team's schedule will be used for their Strength of Schedule calculation. Primarily useful for draft order projections.
    </dd>
  </dl>
  <p>
    At the end of the season, both of these options will yield the same result,
    but it may impact ranking or draft orders during the season. The default is "Games must be
    played", which is the commonly accepted approach.
  </p>
  <p>
    Please keep in mind that this is set on a per-user basis. When linking to
    this league the methodology used will be up to the user (likely the
    default), so they may see different results than you do.
  </p>
</div>
<div class="form-group">
  <h4>Non-playoff team seeding</h4>
  <mat-checkbox
    [(ngModel)]="userSettings.nfl.treatNonPlayoffTeamsLikeWildcards"
    value="userSettings.nfl.treatNonPlayoffTeamsLikeWildcards"
    color="primary"
    >Treat non-playoff teams like wildcards for tiebreaking</mat-checkbox
  >
  <p>
    Default true. If true, there will be a minimum of 4 common games in order to
    use that tiebreaker. If false, it will use ANY common games.
  </p>
</div>
