import { LeagueSettingsService } from "./../../services/league-settings/league-settings.service";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { League } from "@models";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { LeagueService } from "../../services/league/league.service";
import { UserSettingsService } from "@services/index";

@Component({
  selector: "app-scores",
  templateUrl: "./scores.component.html",
  styleUrls: ["./scores.component.css"],
})
export class ScoresComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  numPlayoffTeams: number = this.leagueSettings.numPlayoffTeamsPerConference;
  league: League;
  selectedIndex: number;
  constructor(
    private leagueService: LeagueService,
    private leagueSettings: LeagueSettingsService,
    private userSettings: UserSettingsService
  ) {}

  ngOnInit() {
    this.league = this.leagueService.league$.value;
    this.leagueService.league$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: League) => {
        this.league = res;
      });
    this.selectedIndex =
      this.userSettings.userConfig.nfl.standingsVisibleConference || 0;
  }

  setConference(visibleConf: string) {
    let confIdx = 0;
    switch (visibleConf) {
      case "AFC":
        confIdx = 0;
        break;
      case "NFC":
        confIdx = 1;
        break;
      default:
        confIdx = 2;
        break;
    }
    if(this.userSettings.userConfig.nfl.standingsVisibleConference != confIdx){
      this.userSettings.userConfig.nfl.standingsVisibleConference = confIdx;
      this.userSettings.saveStorage();
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
