
<div id="team-breakout">
  <div class="header-wrapper">
    <div class="team-header" [ngClass]="'pg-' + team.teamAbbreviation">
      <div class="team-logo"><img src="https://static.playoffpredictors.com/nfl/{{ team.teamLogo || team.teamAbbreviation }}.svg" height="120px" class="pull-left team-logo" /></div>
      <div class="team-information">
        <div class="region-name">{{ team.regionName }}</div>
        <div class="team-name">{{ team.teamName }}</div>
      </div>
    </div>
  </div>
  <div [ngClass]="'pg-' + team.teamAbbreviation" class="statbox">
    <div>
      <span>Conf. Rank</span> <span>{{ team.conferenceRank }}</span>
    </div>
    <div>
      <span>Record</span> <span> {{ team.gameRecords.allRecord.toString() }} ({{ team.gameRecords.allRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div>
      <span>Div</span> <span> {{ team.gameRecords.divisionRecord.toString() }} ({{ team.gameRecords.divisionRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div>
      <span>Conf</span> <span> {{ team.gameRecords.conferenceRecord.toString() }} ({{ team.gameRecords.conferenceRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div matTooltip="Pythagorean Expectation" *ngIf="userSettings.userConfig.nfl.enableScoring">
      <span>PE</span>
      <span>{{ team.nflPythagoreanExpectation || 0 | number: '1.1-1' }} </span>
    </div>
    <div>
      <span>BYE WK</span> <span>{{ team.byeWeek }}</span>
    </div>
  </div>
  <div [ngClass]="'pg-' + team.teamAbbreviation" class="statbox">
    <div>
      <span>Home</span> <span> {{ team.gameRecords.homeRecord.toString() }} ({{ team.gameRecords.homeRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div>
      <span>Away</span> <span> {{ team.gameRecords.awayRecord.toString() }} ({{ team.gameRecords.awayRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div matTooltip="Teams > 0.5">
      <span>vs Winning</span> <span> {{ team.gameRecords.winningTeamsRecord.toString() }} ({{ team.gameRecords.winningTeamsRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div matTooltip="Teams <= 0.5">
      <span>vs Losing</span> <span> {{ team.gameRecords.losingTeamsRecord.toString() }} ({{ team.gameRecords.losingTeamsRecord.pct || 0 | number: '1.3-3' }}) </span>
    </div>
    <div matTooltip="Teams whose rank is currently in playoff position">
      <span>vs Playoff Teams</span> <span> {{ team.ptRecText }} ({{ team.PTRecord || 0 | number: '1.3-3' }}) </span>
    </div>

    <div matTooltip="{{ team.sovFractionalDisplay }}">
      <span>SOV</span> <span>{{ team.SOV || 0 | number: '1.3-3' }}</span>
    </div>
    <div matTooltip="{{ team.sosFractionalDisplay }}">
      <span>SOS</span> <span>{{ team.SOS || 0 | number: '1.3-3' }}</span>
    </div>
  </div>
  <div style="display:flex;">
    <app-team-schedule class="flex-column" [games]="team.displaySchedule" (teamPicked)="pickTeam($event)" style="display: flex; flex: 1 1 50%;"></app-team-schedule>
    <div class="flex-column" style="display:flex;flex:1 1 25%">

      <table *ngIf="team.tiebreakers.DivHTH.length">
        <thead>
          <tr>
            <th>Div HTH</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let div of team.tiebreakers.DivHTH as data">
            <td>
              <span>{{ div }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="team.tiebreakers.HTH.length > 0">
        <thead>
          <tr>
            <th>HTH Sweeps Over</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let hth of team.tiebreakers.HTH as data1">
            <td>{{ hth }}</td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="team.tiebreakers?.Sweeps?.length > 0">
        <thead>
          <tr>
            <th>Sweeps Over</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sweep of team.tiebreakers.Sweeps as data2">
            <td>{{ sweep }}</td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="this.Object.keys(team.tiebreakers.CommonGames).length > 0">
        <thead>
          <tr>
            <th>Team</th>
            <th>Rec</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of (team.tiebreakers['CommonGames'] | keyvalue)">
            <td>
              <span>{{ item.key }}</span>
            </td>
            <td>
              <span>{{ item.value[0] }}-{{ item.value[1] }}{{ item.value[2] != 0 ? '-' + item.value[2] : '' }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody *ngIf="team.tiebreakers['Explanations']">
          <tr>
            <td colspan="2">
<ol>
  <li *ngFor="let explain of team.getExplanationsDistinct">
    {{explain}}
  </li>
</ol>

            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colspan="3">
              <p><strong>Rank determined by</strong></p>
              <p>{{ team.rankMethodText }}</p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><button mat-raised-button  aria-label="Back to Schedule" color="accent" mat-button (click)="returnToSchedule()">Back</button></td>
          </tr>
          <tr>
            <td>
              <app-freestar [adName]="'playoffpredictors_rail_right'" [adDimensions]="'__160x600 __0x0'"></app-freestar>
            </td>
          </tr>
        </tfoot>
      </table>

    </div>


  </div>
</div>
<div class="fab-container">
  <button mat-fab aria-label="Back to Schedule" color="warn" mat-button (click)="returnToSchedule()">Back</button>
</div>
